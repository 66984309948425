import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const pymentsSlice = createSlice({
  name: "payments",
  initialState: {
    paymentList: [],
    loading: false,
    error: null,
  },
  reducers: {
    setPayments: (state, action) => {
      state.items = action.payload.data.ads || [];
    },
  },
});

export const selectCountries = (state) => state?.payments?.items;

export const selectCountryLoading = (state) => state?.payments?.loading;
export const selectCountryError = (state) => state?.payments?.error;

export const { setPayments } = pymentsSlice.actions;
export default pymentsSlice.reducer;
