import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import PageLayoutWrapper from "./components/common/PageLayoutWrapper";
import ChatPageLayoutWrapper from "./components/common/ChatPageLayoutWrapper";
// import PrivateRoute from "./components/common/PrivateRoute";
// import PrivateRoute from './components/common/PrivateRoute';
const PrivateRoute = lazy(() => import("./components/common/PrivateRoute"));

// Lazy load components
const HomePage = lazy(() => import("./pages/HomePage"));
const AdPage = lazy(() => import("./pages/Ad"));
const AdListPage = lazy(() => import("./pages/AdListPage"));
const CreatePostPage = lazy(() => import("./pages/CreatePost"));
const ExtendPostPage = lazy(() => import("./pages/ExtendPost"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const AccountPage = lazy(() => import("./pages/AccountPage"));
const AboutUs = lazy(() => import("./pages/AboutUsPage"));
const FAQ = lazy(() => import("./pages/FAQ"));
const SignupPage = lazy(() => import("./pages/SignupPage"));
const CreatePostSuccessPage = lazy(() =>
  import("./pages/CreatePostSuccessPage")
);
const ConfigPage = lazy(() => import("./pages/ConfigPage"));
const CreatePostErrorPage = lazy(() => import("./pages/CreatePostErrorPage"));
const UpdatePasswordCard = lazy(() =>
  import("./components/accountpage/UpdatePasswordCard")
);
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ChatPage = lazy(() => import("./pages/ChatInterface"));

// Routes configuration
const routes = [
  {
    element: <PageLayoutWrapper />, // This will wrap all child routes with layout
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <UpdatePasswordCard />,
      },
      {
        path: "/sign-up",
        element: <SignupPage />,
      },
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/ad/:adId",
        element: <AdPage />,
      },
      {
        path: "/list",
        element: <AdListPage />,
      },
      {
        path: "/list/:categoryId",
        element: <AdListPage />,
      },
      {
        path: "/create",
        element: (
          <PrivateRoute>
            <CreatePostPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/edit/:Id",
        element: (
          <PrivateRoute>
            <CreatePostPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/re-post/:Id",
        element: (
          <PrivateRoute>
            <CreatePostPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/create/success",
        element: (
            <CreatePostSuccessPage />
        ),
      },
      {
        path: "/create/error",
        element: (
            <CreatePostErrorPage />
        ),
      },
      {
        path: "/extend/:Id",
        element: (
          <PrivateRoute>
            <ExtendPostPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/extend/success",
        element: (
            <CreatePostSuccessPage />
        ),
      },
      {
        path: "/extend/error",
        element: (
            <CreatePostErrorPage />
        ),
      },
      {
        path: "/account/*",
        element: (
          <PrivateRoute>
            <AccountPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/about",
        element: <ConfigPage />,
      },
      {
        path: "/contact",
        element: <AboutUs />,
      },
      {
        path: "/terms-and-conditions",
        element: <ConfigPage />,
      },
      {
        path: "/privacy-policy",
        element: <ConfigPage />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
     
      // Catch-all route to handle unknown paths
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    element: <ChatPageLayoutWrapper />, // This will wrap all child routes with layout
    children: [
      {
        path: "/chat",
        element: (
          <PrivateRoute>
            <ChatPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/chat/:id",
        element: (
          <PrivateRoute>
            <ChatPage />
          </PrivateRoute>
        ),
      }
    ],
  },
];

export default routes;
