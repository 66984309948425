import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const adSlice = createSlice({
  name: "ad",
  initialState: {
    featuredAdList: [],
    newArrivalsAdList: [],
    items: [],
    savedAds: {},
    selectedAdDetails: {},
    loading: false,
    error: null,
  },
  reducers: {
    setAds: (state, action) => {
      state.items = action.payload.data.ads || [];
    },
    setFeaturedAdList: (state, action) => {
      state.featuredAdList = action.payload || [];
      state.loading = false;
    },
    setNewArrivalsAdList: (state, action) => {
      state.newArrivalsAdList = action.payload || [];
      state.loading = false;
    },
    setSelectedAdDetails: (state, action) => {
      state.selectedAdDetails = action.payload ||{};
      state.loading = false;
    },
    setSavedAds: (state, action) => {
      state.savedAds = action.payload || {};
      state.loading = false;
    },
    clearSelectedAdDetails:(state)=>{
      state.selectedAdDetails =[]; 
    }
  },
});

export const selectAds = (state) => state?.ad?.items;

export const selectAdLoading = (state) => state?.ad?.loading;
export const selectAdError = (state) => state?.ad?.error;

export const {
  setFeaturedAdList,
  setNewArrivalsAdList,
  setSelectedAdDetails,
  setSavedAds,
  setAds,
  clearSelectedAdDetails
} = adSlice.actions;
export default adSlice.reducer;