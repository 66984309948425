import axios from 'axios';
import { getAccessToken, getRefreshToken, setTokens } from './auth';

// Create an Axios instance
const api = axios.create({
    //baseURL: 'http://localhost:8000/api',
     baseURL: 'https://sold.dxg.world/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    async (config) => {
        const accessToken = getAccessToken(); // Function to get access token from storage
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle token refresh and retries
api.interceptors.response.use(
    (response) => {
        return response; // Simply return response if successful
    },
    async (error) => {
        const originalRequest = error.config;

        // If the request failed with a 401 error, try refreshing the token
        if (error?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = getRefreshToken(); // Function to get refresh token from storage
                const response = await api.post('/auth/refresh-token', { refreshToken });
                let data = response.data
                if(data.success){
                    const { accessToken, refreshToken: newRefreshToken } = data.data;
                    if(accessToken){
                        setTokens(accessToken, newRefreshToken); // Save new tokens
                        // Set the new access token and retry the failed request
                        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        
                        return api(originalRequest); // Retry the original request
                    }
                }else{
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('user');
                    window.location.href='/login'
                }
            } catch (refreshError) {
                // Handle token refresh failure (e.g., redirect to login page)
                console.error('Token refresh failed:', refreshError);
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default api;

