import React, {  useEffect, useState } from 'react';
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Box,
  Collapse,
  Skeleton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useCategory from '../../hooks/useCategory';
import { useNavigate } from 'react-router-dom';

function groupByParentId(data) {
  return data.reduce((acc, category) => {
    const parentId = category.parentId || "ALL";
    if (!acc[parentId]) {
      acc[parentId] = [];
    }
    acc[parentId].push(category);
    return acc;
  }, {});
}

const CategoryItem = ({ category, level, categoryList, handleItemClick }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    if (categoryList[category._id]) {
      setOpen(!open);
    }
  };

  return (
    <>
      <ListItem
        button
        onClick={() => handleItemClick(category._id)}
        sx={{
          padding: '0px',
          pl: 2 + level * 2,
          pr: 2,
          border: 'none',
          backgroundColor: "primary.main",
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)',
          },
        }}
      >
        <ListItemText
          primary={category.name.en}
          sx={{
            '& .MuiListItemText-primary': {
              fontSize: '14px',
              fontWeight: 500,
              padding: '0px',
            },
          }}
        />
        {open ? (
          <ExpandMoreIcon sx={{ opacity: 0.7 }} onClick={handleClick} />
        ) : (
          <ChevronRightIcon onClick={handleClick} sx={{ opacity: 0.7 }} />
        )}
      </ListItem>
      {categoryList[category._id] && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {categoryList[category._id].map((subCategory, index) => (
              <CategoryItem
                key={index}
                categoryList={categoryList}
                handleItemClick={handleItemClick}
                category={subCategory}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default function NestedCategoriesMenu({ anchorEl, open, onClose,setMobileOpen }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [categoryList, setCategoryList] = useState({});
  const { fetchCategoryListForDropdown } = useCategory();
  const navigate = useNavigate();

  const handleItemClick = (id) => {
    setMobileOpen()
    onClose();
    navigate(`/list/${id}`);
  };

  const fetchCategoryList = async () => {
    try {
      setLoading(true);
      const response = await fetchCategoryListForDropdown();
      if (response.success) {
        setCategoryList(groupByParentId(response.data));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  return (
    <Box >
      {Boolean(anchorEl) && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#F06B5B',
              color: 'white',
              width: isMobile ? '100%' : '300px',
              position: isMobile ? 'fixed' : 'relative',
              top: isMobile ? 0 : 'auto',
              left: isMobile ? 0 : 'auto',
              right: isMobile ? 0 : 'auto',
              maxHeight: isMobile ? '80vh' : '60vh',
              overflowY: 'auto',
              zIndex: isMobile ? 1500 : 'auto',
            },
          }}
        >
          {loading ? (
            <List sx={{ p: 0 }}>
              {[...Array(5)].map((_, index) => (
                <ListItem key={index} sx={{ pl: 2, pr: 2 }}>
                  <Skeleton variant="text" width="100%" />
                </ListItem>
              ))}
            </List>
          ) : (
            <List
              sx={{
                p: 0,
                '& .MuiListItem-root': {
                  borderBottom: '1px solid rgba(255,255,255,0.1)',
                },
              }}
            >
              {categoryList['ALL']?.map((category, index) => (
                <CategoryItem
                  key={index}
                  category={category}
                  handleItemClick={handleItemClick}
                  categoryList={categoryList}
                  level={0}
                />
              ))}
            </List>
          )}
        </Popover>
      )}
    </Box>
  );
}
